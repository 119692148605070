export default defineNuxtPlugin(() => {
  const { monetateConfig, configP13n } = useFeatureFlags()

  if (!monetateConfig) return

  const { brand } = useRuntimeConfig().public
  const region = useRegion()
  const {
    enableBloomReach,
    bloomReachHost,
    bloomReachEndpointIds,
    bloomReachBasicAuth,
    bloomReachProjectId
  } = configP13n

  useHead(
    {
      link: [
        { href: 'https://engine.monetate.net', rel: 'preconnect' },
        ...(bloomReachHost ? [{ href: `https://${bloomReachHost}`, rel: 'preconnect' }] : [])
      ],
      script: [
        {
          innerHTML: `
            window.vfaConfig = { 
              monetateChannel: '${monetateConfig.monetateChannel}', 
              siteId: '${useApiHeaders().siteId}', 
              brand: '${brand}', 
              region: '${region}',
              bloomReachEndpoint: '${enableBloomReach ? `https://${bloomReachHost}/webxp/s/${bloomReachProjectId}/managed-endpoints/` : ''}',
              bloomReachEndpointIds: ${enableBloomReach ? JSON.stringify(bloomReachEndpointIds) : 'null'},
              bloomReachBasicAuth: '${enableBloomReach ? bloomReachBasicAuth : ''}'
            }`,
          tagPosition: 'head',
          id: 'vfa-config'
        },
        { src: '/scripts/prefetch-p13n.js', type: 'module', fetchpriority: 'high' }
      ],
    },
  )
})
