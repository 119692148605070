<template>
  <transition name="nested" :duration="250" appear>
    <component
      :is="dialog.type === 'panel' ? VfPanel : VfModal"
      v-if="dialog"
      v-slot="attrs"
      :type="dialog.type"
      :size="dialog.size"
      :data-test-id="`${dialog.type}-${dialog.name}`"
      @resolve="closeDialog"
    >
      <suspense>
        <component
          :is="`dialog-${dialog.name}`"
          v-bind="{ ...attrs, ...dialog.props }"
          @resolve="closeDialog"
        />
        <template #fallback>
          <div style="min-height: 13.5rem;">
            <vf-page-loader />
          </div>
        </template>
      </suspense>
    </component>
  </transition>
  <vf-page-loader v-if="isLoading" />
</template>

<script lang="ts" setup>
import { VfModal, VfPanel } from '#components'
import { InjectKeyDialog } from '#core/utils/createDialog'
import type { DialogContent } from '#types/components/cms/dialog'
import type { DialogName, DialogSize, DialogType } from '#types/composables/useDialog'

const router = useRouter()
const route = useRoute()
const { getModal, getSections } = useCms()

const isLoading = ref(false)
const dialog = ref<{
  type?: 'panel' | 'modal'
  name?: string
  props?: Record<string, any>
  size?: DialogSize
} | null>(null)
const typeRef = ref<'modal' | 'panel'>()

provide(InjectKeyDialog, { type: typeRef as any })

const initialData = {
  'sign-up': async () => {
    const eSpots = (await getSections.$signUpModal())?.content.sectionsMap

    return {
      props: { eSpot: eSpots?.['below-sign-up'] },
      size: eSpots?.['below-sign-up'] ? 'md' : 'xs'
    }
  }
}

async function getDialog(hash: string) {
  const [type, _ = ''] = hash.replace('#', '').split('=') as [DialogType, string]
  const [name, param] = _.split('_') as [DialogName, string | undefined]

  if (!['modal', 'panel'].includes(type)) return null

  if (name === 'content' && param) {
    isLoading.value = true
    const content = await getModal<DialogContent>(param)
    isLoading.value = false
    typeRef.value = type

    return {
      type,
      name,
      props: { content },
      size: content.modalSize
    }
  }
  const initial = await initialData[name]?.()

  return { type, name, ...initial }
}

watchImmediate(() => route.hash, async (hash) => {
  dialog.value = hash ? await getDialog(hash) : null
})

const closeDialog = () => {
  router.replace({ hash: undefined, query: route.query })
  dialog.value = null
}
</script>
