import type { RouteLocation } from 'vue-router'
import type { MonetateEventType, MonetatePersonalInfo } from '#types/p13n'
import { gtm } from '#root/env/features'
import { extractBaseEvents, extractExtraEvents, mapItemLine, reduceActions } from '#core/utils/p13n'

declare module '#app' {
  interface NuxtApp {
    $sendExtraMonetateEvents: (data?: any, event?: MonetateEventType) => void
  }
}
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $sendExtraMonetateEvents: (data?: any, event?: MonetateEventType) => void
  }
}

export default defineNuxtPlugin({
  name: 'monetate',
  dependsOn: gtm ? ['gtm'] as any : [],
  setup() {
    const route = useRoute()
    const auth = useAuthStore()
    const countryCode = useCountryCode()
    const profileStore = useProfileStore()
    const { sendMonetateEvents, sendMonetateEventsToTracking, channel } = useMonetate()

    const { monetateConfig } = useFeatureFlags()
    const coordinates = useCookie('Edgescape-Lat-Long').value

    monetateConfig.isMonetateTagActive && useScript(`https://se.monetate.net/js/2/${channel}/entry.js`)

    const getBaseEvents = (to: RouteLocation) => {
      if (!monetateConfig?.isMonetateActive) return []
      const { width, height } = useWindowSize()

      const lsCart = useLocalStorage(`cart_${countryCode}`, {} as any)
      const cart = lsCart?.value || {}
      const { totals = {} } = cart

      if (useRuntimeConfig().public.targetEnv === 'PREPROD' && useCookie('stealthGroup').value !== 'trackingGroup')
        useCookie('stealthGroup', { maxAge: 3600 }).value = to.query?.bypassStealthGroup ? 'trackingGroup' : 'stealthGroup'

      const monetatePersonalInfo: MonetatePersonalInfo = {
        height: height.value,
        width: width.value,
        coordinates,
        customStates: {
          cartEmpty: !cart.totalItems,
          employeeLoggedIn: auth.consumerType === 'EMPLOYEE',
          loggedIn: auth.loggedIn,
          loyaltyEnrolled: !!profileStore.profile?.isLoyaltyMember,
        },
        interests: profileStore.interests,
        cart: {
          cartItemTotal: totals.itemTotal,
          cartDiscount: totals.totalDiscount,
          items: cart.items?.map(mapItemLine)
        },
        stealth: useCookie('stealthGroup').value
      }
      useLocalStorage('monPersonalInfo', monetatePersonalInfo, { mergeDefaults: true }).value = monetatePersonalInfo
      return extractBaseEvents(to, monetatePersonalInfo)
    }

    const sendExtraMonetateEvents = async (
      eventContent?: any,
      eventType?: MonetateEventType
    ) => {
      if (import.meta.server || !monetateConfig?.isMonetateActive || !hasCookieConsent(['targeting']))
        return

      const additionalEvents = eventType
        ? extractExtraEvents(
          eventContent,
          eventType,
          getEnvFromChannel(monetateConfig.monetateChannel) === 'PROD' ? 'p' : 'd'
        )
        : []

      const events = [
        ...getBaseEvents(route),
        ...additionalEvents
      ]

      if (!additionalEvents.length && eventType) return
      const actions = await sendMonetateEvents(events)
      if (actions) window.vfa = reduceActions(actions)
      window.dispatchEvent(new Event('recalculateMonetateVfa'))
    }

    watch(() => route.fullPath, async (newVal, oldVal) => {
      if (newVal !== oldVal) {
        getBaseEvents(route)
        oldVal ? await window.vfaInit(true) : await window.vfaInitPromise
        if (window.vfa) sendMonetateEventsToTracking([...window?.vfa.values()])
      }
    }, { immediate: true })

    // Send extra monetate events when loyalty status changes
    // Loyalty status is a good indicator of whether the user is logged in or not because consumer information is loaded after the user logs in
    watch(() => profileStore.isLoyaltyMember, () => sendExtraMonetateEvents())

    return {
      provide: {
        sendExtraMonetateEvents
      }
    }
  }
})
